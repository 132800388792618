import { GoogleOAuthProvider } from '@react-oauth/google';
import 'antd/dist/antd.css';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import './App.scss';
import './antd-custom.scss';
import api from './api/api';
import { returnErrorPage } from './components/Fallbacks/ErrorScreenHandler';
import { GOOGLE_CLIENT_ID } from './constants/config';
import { EMPLOYEE_URLS } from './constants/types';
import DashboardLayout from './pages/Dashboard/DashboardLayout';
import LoginLayout from './pages/Login/LoginLayout';
import Layout from './pages/Retail/Layout';
import UpgradationLayout from './pages/Upgradation/UpgradationLayout';
import { authActions } from './store/auth';
import { flagActions } from './store/flags';
import { themeActions } from './store/theme';
import { deleteLocalData, errorHandler, getLocalData, setAxiosHeaders, setLocalData } from './utils/helper';
import { trackData } from './utils/trackers';
import Loader from './components/Loader/Loader';
// import { onLoadRemoveLoader } from '.';

// ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_CODE);
// mixpanel.init(MIXPANEL, { debug: true });
declare global {
  interface Window {
    webengage: any;
  }
}

const App = (props: any) => {
  const dispatch = useDispatch(),
    [theme, setTheme] = useState<any>({
      primaryColor: '',
      favicon: '',
      title: '',
      description: '',
    }),
    navigate = useNavigate(),
    [searchParams, setSearchParams] = useSearchParams(),
    [customUrls, setCustomUrls] = useState<any>([]),
    [loading, setLoading] = useState(true),
    [hasBrokerConfigLoaded, setHasBrokerConfigLoaded] = useState(false),
    email = useSelector((state: any) => state.auth.email),
    isAuthenticated = useSelector((state: any) => state.auth.isLoggedIn),
    userData = useSelector((state: any) => state.auth),
    uhi = useSelector((state: any) => state.auth.uhi),
    hasErrors = useSelector((state: any) => state.error.id),
    location = useLocation(),
    isUHIActive = location.pathname.includes('upgrade-policy') ? true : false,
    isSQ = location.pathname.includes('suraksha-qr') ? true : false,
    isRetailActive = location.pathname.includes('retail') ? true : false,
    targetURLCheck = (url: string, response: any) => {
      if (getLocalData(url)) {
        if (response?.roles?.includes('employer')) {
          if (getLocalData('activeMode')) {
            dispatch(authActions.setActiveMode(getLocalData('activeMode')));
          } else if (EMPLOYEE_URLS.filter((item: any) => getLocalData(url)?.includes(item))?.length === 1) {
            dispatch(authActions.setActiveMode('employee'));
          } else {
            dispatch(authActions.setActiveMode('employer'));
          }
        } else {
          dispatch(authActions.setActiveMode('employee'));
        }
        navigate(getLocalData(url));
        deleteLocalData(url);
      }
    },
    authCheck = () => {
      if (!isAuthenticated) {
        if (!location.pathname.includes('password') && !location.pathname.includes('otp')) {
          setLocalData('targetUrl', window.location.href.split('/').slice(3).join('/'));
        }
        if (searchParams.get('access_token') && searchParams.get('oid')) {
          api.profile
            .ssoLogin({ access_token: searchParams.get('access_token'), oid: searchParams.get('oid') })
            .then((res) => {
              if (res?.access_token && res?.refresh_token) {
                dispatch(authActions.login({ accessToken: res?.access_token, refreshToken: res?.refresh_token }));
              }
            })
            .catch((err) => {
              setSearchParams({});
            });
        }
        getBrokerConfig();
      }
      setAxiosHeaders();
      if (isAuthenticated) {
        setLocalData('targetUrl2', window.location.href.split('/').slice(3).join('/'));
        api.profile
          .userData()
          .then((response) => {
            window.webengage?.user.login(response?.member_uuid);
            window.webengage?.user.setAttribute('we_email', response?.email);
            window.webengage?.user.setAttribute('we_first_name', response?.first_name);
            window.webengage?.user.setAttribute('status', response?.status);
            window.webengage?.user.setAttribute('is_admin', response?.is_admin);
            window.webengage?.user.setAttribute('organisation_id', response?.organisation_id);
            if (response?.phone) {
              window.webengage?.user.setAttribute('we_phone', `+91${response?.phone}`);
            }
            window.webengage?.user.setAttribute('we_date_of_birth', response?.date_of_birth);
            window.webengage?.user.setAttribute('we_gender', response?.gender?.toLowerCase());
            window.webengage?.user.setAttribute('is_fti_available', response?.is_uhi_available);
            dispatch(
              authActions.userData({
                id: response?.id,
                uuid: response?.uuid,
                member_id: response?.member_id,
                member_uuid: response?.member_uuid,
                status: response?.status,
                roles: response?.roles,
                name: response?.full_name || response?.first_name,
                organisation_id: response?.organisation_id,
                organisation_uuid: response?.organisation_uuid,
                relationship_managers: response?.relationship_manager,
                // uhi: { is_uhi_available: response?.is_uhi_available },
                email: response?.email,
                phone: response?.phone,
              })
            );
            // dispatch(authActions.setUHI({ uhi: { is_uhi_available: response?.is_uhi_available } }));

            if (!isRetailActive) {
              api.UHI.renewalStatus()
                .then((res) => {
                  dispatch(authActions.setUHI({ uhi: res?.data }));
                  window.webengage?.user.setAttribute('manipal_policy_info', res?.data);
                })
                .catch((error) => {
                  console.log(error);
                });
            }

            targetURLCheck('targetUrl2', response);
            targetURLCheck('targetUrl', response);
          })
          .catch((error) => {});
      }
    },
    getBrokerConfig = (organisation_uuid?: any) => {
      setLoading(true);
      api.broker
        .getBrokerConfig({
          organisation_uuid: organisation_uuid || userData?.organisation_uuid,
        })
        .then((response) => {
          // onLoadRemoveLoader();
          if (isSQ) {
            dispatch(
              themeActions.setTheme({
                primaryColor: '#0FA3B1',
                logoUrl: response?.data?.organisation_config?.logo_url
                  ? response?.data?.organisation_config?.logo_url
                  : response?.data?.logo_url,
                brandName: response?.data?.organisation_config?.brand_name,
                loginOptions: response?.data?.login_services || {},
                isDark: response?.data?.organisation_config?.is_dark,
                brokerName: response?.data?.brand_name,
                description: response?.data?.addition_details?.tagline || `Employee Benefits Platform`,
                supportPhone: response?.data?.support_phone || '',
                supportEmail: response?.data?.support_email || '',
              })
            );
            setTheme({
              primaryColor: '#0FA3B1',
              logoUrl: response?.data?.organisation_config?.logo_url
                ? response?.data?.organisation_config?.logo_url
                : response?.data?.logo_url,
              favicon: response?.data?.favicon_url,
              title: response?.data?.brand_name,
              description: response?.data?.addition_details?.tagline || `Employee Benefits Platform`,
              loginOptions: response?.data?.login_services || {},
              isDark: response?.data?.organisation_config?.is_dark,
            });
          } else {
            window?.webengage?.user?.setAttribute('we_corporate_name', response?.data?.organisation_config?.brand_name);
            window?.webengage?.user?.setAttribute('broker', response?.data?.brand_name);
            dispatch(
              themeActions.setTheme({
                primaryColor: response?.data?.organisation_config?.theme_color
                  ? response?.data?.organisation_config?.theme_color
                  : response?.data?.theme_color,
                logoUrl: response?.data?.organisation_config?.logo_url
                  ? response?.data?.organisation_config?.logo_url
                  : response?.data?.logo_url,
                brandName: response?.data?.organisation_config?.brand_name,
                loginOptions: response?.data?.login_services || {},
                isDark: response?.data?.organisation_config?.is_dark,
                brokerName: response?.data?.brand_name,
                description: response?.data?.addition_details?.tagline || `Employee Benefits Platform`,
                supportPhone: response?.data?.support_phone || '',
                supportEmail: response?.data?.support_email || '',
              })
            );
            setTheme({
              primaryColor: response?.data?.organisation_config?.theme_color
                ? response?.data?.organisation_config?.theme_color
                : response?.data?.theme_color,
              logoUrl: response?.data?.organisation_config?.logo_url
                ? response?.data?.organisation_config?.logo_url
                : response?.data?.logo_url,
              favicon: response?.data?.favicon_url,
              title: response?.data?.brand_name,
              description: response?.data?.addition_details?.tagline || `Employee Benefits Platform`,
              loginOptions: response?.data?.login_services || {},
              isDark: response?.data?.organisation_config?.is_dark,
            });
          }

          dispatch(
            flagActions.setAutoInvitationDays({
              invitation_auto_accept_days: response?.data?.organisation_config?.invitation_auto_accept_days,
            })
          );

          if (response?.data?.addition_details?.client?.google_analytics) {
            ReactGA.initialize(response?.data?.addition_details?.client?.google_analytics);
          }
          if (response?.data?.client?.google_analytics) {
            ReactGA.initialize(response?.data?.client?.google_analytics);
          }
          if (response?.data?.addition_details?.client?.mixpanel) {
            setLocalData('mixpanel', response?.data?.addition_details?.client?.mixpanel);
          }
          if (response?.data?.client?.mixpanel) {
            setLocalData('mixpanel', response?.data?.client?.mixpanel);
          }
          if (response?.data?.addition_details?.client?.custom_script_urls) {
            setCustomUrls(response?.data?.addition_details?.client?.custom_script_urls?.split(','));
            response?.data?.addition_details?.client?.custom_script_urls?.split(',').map((url: string) => {
              var script = document.createElement('script');
              script.type = 'text/javascript';
              script.src = url;
              document.head.appendChild(script);
            });
          }
          if (response?.data?.client?.custom_script_urls) {
            setCustomUrls(response?.data?.client?.custom_script_urls?.split(','));
            response?.data?.client?.custom_script_urls?.split(',').map((url: string) => {
              var script = document.createElement('script');
              script.type = 'text/javascript';
              script.src = url;
              document.head.appendChild(script);
            });
          }
          if (response?.data?.brand_name === 'Healthysure') {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://widgets.in.webengage.com/js/service-worker.js';
            document.head.appendChild(script);
          }
          if (response?.data?.addition_details?.client?.custom_code) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.innerHTML = response?.data?.addition_details?.client?.custom_code;
            document.head.appendChild(script);
          }
          if (response?.data?.client?.custom_code) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.innerHTML = response?.data?.client?.custom_code;
            document.head.appendChild(script);
          }
          if (organisation_uuid) {
            setLoading(false);
          }
        })
        .catch((error: any) => {
          errorHandler(error?.response?.data);
          if (organisation_uuid) {
            setLoading(false);
          }
        });
    };

  useEffect(() => {
    authCheck();
  }, [isAuthenticated]);

  useEffect(() => {
    if (userData?.organisation_uuid) {
      getBrokerConfig(userData?.organisation_uuid);
    }
  }, [userData?.organisation_uuid]);

  useEffect(() => {
    if (email && customUrls.filter((item: any) => item.includes('pagesense'))) {
      var script2 = document.createElement('script');
      script2.type = 'text/javascript';
      script2.innerHTML = `window.pagesense = window.pagesense || [];
      window.pagesense.push(['identifyUser', '${email}']);
      window.pagesense.push(['trackUser', {'role':'${
        userData.roles.includes('employer') ? 'employer' : 'employee'
      }', 'fti':'${uhi?.is_uhi_available}'} ]);`;
      document.head.appendChild(script2);
    }
  }, [email, userData]);

  useEffect(() => {
    trackData('View Page', { URL: location.pathname });
  }, [location]);

  useEffect(() => {
    document.title = theme.title + ': ' + theme.description;
    document.querySelector('meta[name="description"]')?.setAttribute('content', theme.description);
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', theme.primaryColor);
    document.querySelector('meta[name="msapplication-navbutton-color"]')?.setAttribute('content', theme.primaryColor);
    document
      .querySelector('meta[name="apple-mobile-web-app-status-bar-style"]')
      ?.setAttribute('content', theme.primaryColor);
    if (!isUHIActive) {
      document.documentElement.style.setProperty('--primary-color', theme.primaryColor);
      document.documentElement.style.setProperty('--primary-color-light', theme.primaryColor + '40');
      document.documentElement.style.setProperty('--primary-color-lighter', theme.primaryColor + '20');
      document.documentElement.style.setProperty('--text-color', theme.isDark ? '#fff' : '#000');
    }
    let link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = theme.favicon;

    // Dynamic manifest
    var myDynamicManifest = {
      name: theme.title,
      short_name: theme.title,
      description: theme.description,
      background_color: '#000000',
      theme_color: theme.primaryColor,
      start_url: '.',
      scope: '.',
      display: 'standalone',
      icons: [
        {
          src: theme.favicon,
          sizes: '64x64 32x32 24x24 16x16',
          type: 'image/x-icon',
          purpose: 'any maskable',
        },
        {
          src: theme.favicon,
          sizes: '192x192',
          type: 'image/png',
          purpose: 'any maskable',
        },
        {
          src: theme.favicon,
          sizes: '512x512',
          type: 'image/png',
          purpose: 'any maskable',
        },
      ],
    };
    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);
    let maniFest: any = document.querySelector('#my-manifest-placeholder');
    maniFest.href = manifestURL;
    if (theme.logoUrl)
      setTimeout(() => {
        setHasBrokerConfigLoaded(true);
      }, 400);
  }, [theme]);

  useEffect(() => {
    if (isUHIActive) {
      document.documentElement.style.setProperty('--primary-color', '#11A387');
      document.documentElement.style.setProperty('--primary-color-light', '#11A38740');
      document.documentElement.style.setProperty('--text-color', '#fff');
    } else {
      document.documentElement.style.setProperty('--primary-color', theme.primaryColor);
      document.documentElement.style.setProperty('--primary-color-light', theme.primaryColor + '40');
      document.documentElement.style.setProperty('--text-color', theme.isDark ? '#fff' : '#000');
    }
  }, [isUHIActive]);

  useEffect(() => {
    const isPhoneApp = searchParams.get('isPhoneApp');
    if (isPhoneApp) {
      setLocalData('isPhoneApp', isPhoneApp);
    }
  }, []);

  if (!hasBrokerConfigLoaded) {
    return (
      <Loader loading>
        <></>
      </Loader>
    );
  }

  // useEffect(() => {
  //   const socket = setupWebSocket();
  //   // Clean up the WebSocket connection when the component unmounts
  //   return () => {
  //     socket.close();
  //   };
  // }, []);

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      {/* {loading && <Spin />} */}
      {/* {!loading && <> */}
      {hasErrors && returnErrorPage(hasErrors)}
      {!hasErrors && (
        <>
          {isUHIActive && (
            <div className="uhi-layout">
              <UpgradationLayout />
            </div>
          )}

          {isRetailActive && (
            <div className="retail-layout">
              <Layout />
            </div>
          )}

          {isAuthenticated && !isUHIActive && !isRetailActive && (
            <div className="layout">
              <DashboardLayout loading={loading} />
            </div>
          )}
          {!isAuthenticated && !isUHIActive && !isRetailActive && (
            <div className="login-layout">
              <LoginLayout />
            </div>
          )}
        </>
      )}
      {/* </>} */}
    </GoogleOAuthProvider>
  );
};

export default App;
