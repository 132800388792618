import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { returnErrorPage } from '../components/Fallbacks/ErrorScreenHandler';
import WebSocketComponent from '../pages/Upgradation/UpgradeForm/ws';
import lazyWithRetry from './lazyWithRetry';
const EmployeeRequests = lazyWithRetry(() => import('../pages/Dashboard/Members/ManageRequests/EmployeeRequests'));
const Login = lazyWithRetry(() => import('../pages/Login/LoginScreen/Login'));
const CheckSSO = lazyWithRetry(() => import('../pages/Login/LoginScreen/CheckSSO'));
const IssuanceFailure = lazyWithRetry(() => import('../pages/Upgradation/UpgradeForm/IssuanceFailure'));
const Product = lazyWithRetry(() => import('../pages/Dashboard/Members/AddMember/ProductDetails/Product'));
const Bulk = lazyWithRetry(() => import('../pages/Dashboard/Members/BulkAddition/Bulk'));
const ReviewScreen = lazyWithRetry(() => import('../pages/Dashboard/Members/BulkAddition/ReviewScreen'));
const UploadScreen = lazyWithRetry(() => import('../pages/Dashboard/Members/BulkAddition/UploadScreen'));
const SettingsLayout = lazyWithRetry(() => import('../pages/Dashboard/Settings/SettingsLayout'));
const CongratulationsScreen = lazyWithRetry(() => import('../pages/Upgradation/UpgradeForm/CongratulationsScreen'));
const PaymentFailure = lazyWithRetry(() => import('../pages/Upgradation/UpgradeForm/PaymentFailure'));
const UpgradingScreen = lazyWithRetry(() => import('../pages/Upgradation/UpgradeForm/UpgradingScreen'));
const ClaimsDetails = lazyWithRetry(() => import('../pages/Dashboard/Claims/ClaimsDetails'));
const ProductsHRMS = lazyWithRetry(() => import('../pages/Dashboard/Members/HRMSAddition/ProductsHRMS'));
const HowToClaim = lazyWithRetry(() => import('../pages/Dashboard/Claims/AddClaim/HowToClaim'));
const Claims = lazyWithRetry(() => import('../pages/Dashboard/Claims/Claims'));
const ProfileSection = lazyWithRetry(() => import('../pages/Dashboard/Profile/ProfileSection'));
const ProfileLayout = lazy(() => import('../pages/Dashboard/Profile/ProfileLayout'));
const RequestOTPBPIDA = lazy(() => import('../pages/Login/OTPScreenBP/RequestOTPIDA'));
const RequestOTPBP = lazy(() => import('../pages/Login/OTPScreenBP/RequestOTP'));
const OTPScreenBP = lazy(() => import('../pages/Login/OTPScreenBP/OTPScreen'));
const OTPScreenBPIDA = lazy(() => import('../pages/Login/OTPScreenBP/OTPScreenIDA'));
const AwaitingApproval = lazy(() => import('../pages/Dashboard/InvitedMember/AwaitingApproval'));
const InvitedMember = lazy(() => import('../pages/Dashboard/InvitedMember/InvitedMember'));
const ForgotPassword = lazy((): any => import('../pages/Login/ForgotPassword/ForgotPassword'));
const ClaimsLayout = lazy(() => import('../pages/Dashboard/Claims/ClaimsLayout'));
const AddClaim = lazy(() => import('../pages/Dashboard/Claims/AddClaim/AddClaim'));
const ClaimGuide = lazy(() => import('../pages/Dashboard/Claims/AddClaim/ClaimGuidePage'));
const MembersLayout = lazy(() => import('../pages/Dashboard/Members/MembersLayout'));
const MembersHRMS = lazy(() => import('../pages/Dashboard/Members/HRMSAddition/MembersHRMS'));
const ProductsLayout = lazy(() => import('../pages/Dashboard/Products/ProductsLayout'));
const LandingPage = lazy(() => import('../pages/Upgradation/LandingPage/LandingPage'));
const Base = lazy(() => import('../pages/Upgradation/UpgradeForm/Base'));
const Proposal = lazy(() => import('../pages/Upgradation/UpgradeForm/Proposal'));
const PostPayment = lazy(() => import('../pages/Upgradation/UpgradeForm/PostPayment'));
const AddMember = lazy(() => import('../pages/Dashboard/Members/AddMember/AddMember'));
const SetPassword = lazy(() => import('../pages/Login/SetPassword/SetPassword'));
const ResetPassword = lazy(() => import('../pages/Login/SetPassword/ResetPassword'));
const MemberDetails = lazy(() => import('../pages/Dashboard/Members/AddMember/MemberDetails/MemberDetails'));
const InviteMemberDetails = lazy(() => import('../pages/Dashboard/Members/InviteMember/InviteMemberDetails'));
const InviteMemberProducts = lazy(() => import('../pages/Dashboard/Members/InviteMember/InviteMemberProducts'));
//retail
const RetailLanding = lazyWithRetry(() => import('../pages/Retail/Landing'));
const RetailOfferings = lazyWithRetry(() => import('../pages/Retail/Offerings'));
const RetailCheckout = lazyWithRetry(() => import('../pages/Retail/Checkout'));
const RetailPostPayment = lazyWithRetry(() => import('../pages/Retail/PostPayment'));

const Router: React.FC = () => {
  const isAuthenticated = useSelector((state: any) => state.auth.isLoggedIn),
    activeMode = useSelector((state: any) => state.auth.activeMode),
    status = useSelector((state: any) => state.auth.status),
    isUHIActive = window.location.href.includes('upgrade-policy') ? true : false,
    isBimaPremium = window.location.href.includes('premiumbima') ? true : false,
    [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated) {
      setLoading(false);
    }

    if (isAuthenticated && status && activeMode) {
      setLoading(false);
    }
  }, [activeMode, status, isAuthenticated]);

  if (loading) {
    return (
      <div className="loader-div">
        <div className="loader">
          <Spin />
        </div>
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <div className="loader-div">
          <div className="loader">
            <Spin />
          </div>
        </div>
      }
    >
      <Routes>
        {isBimaPremium === true && (
          <>
            <Route path="/otp" element={<RequestOTPBP />} />
            <Route path="/ida/otp" element={<RequestOTPBPIDA />} />
            <Route path="/verify-otp" element={<OTPScreenBP />} />
            <Route path="/ida/verify-otp" element={<OTPScreenBPIDA />} />
            {/* <Route path="/*" element={<RequestOTPBP />} /> */}
            {/* <Route path="/" element={<RequestOTPBP />} /> */}
          </>
        )}
        {/* {activeMode === 'employee' && ( */}
        <>
          <Route path="/upgrade-policy/*">
            <Route path="*" element={<LandingPage />} />
            <Route path="select-members" element={<Base />} />
            <Route path="proposal" element={<Proposal />} />
            <Route path="post-payment" element={<PostPayment />} />
            <Route path="pp1" element={<UpgradingScreen />} />
            <Route path="pp2" element={<CongratulationsScreen />} />
            <Route path="pp3" element={<PaymentFailure />} />
            <Route path="pp4" element={<IssuanceFailure />} />
          </Route>
        </>
        {/* )} */}

        {status === 'INACTIVE' && (
          <>
            {isAuthenticated && (
              <>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/home" element={<MembersLayout />} />
                <Route path="/claims" element={<ClaimsLayout />} />
                <Route path="/claims/add-claim">
                  <Route path="" element={<AddClaim />} />
                  <Route path=":id" element={<ClaimGuide />} />
                </Route>
              </>
            )}
          </>
        )}

        {status === 'ACTIVE' && (
          <>
            {isAuthenticated && (
              <>
                <Route path="/profile" element={<ProfileLayout />} />
                <Route path="/profile/my-account" element={<ProfileSection />} />
                {activeMode === 'employee' && (
                  <>
                    <Route path="/" element={<Navigate to="/home" />} />
                    <Route path="/home" element={<MembersLayout />} />
                    <Route path="/dashboard" element={<Navigate to="/home" />} />
                    <Route path="/claims" element={<Claims />}>
                      <Route path="" element={<ClaimsLayout />} />
                      <Route path=":uuid" element={<ClaimsDetails />} />
                      <Route path="how-to-claim/:id" element={<HowToClaim />} />
                      <Route path="add-claim">
                        <Route path="" element={<AddClaim />} />
                        <Route path=":id" element={<ClaimGuide />} />
                      </Route>
                    </Route>
                  </>
                )}

                {activeMode === 'employer' && (
                  <>
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="/home" element={<Navigate to="/dashboard" />} />
                    <Route path="/dashboard" element={<ProductsLayout />} />
                    <Route path="/claims" element={<Claims />}>
                      <Route path="" element={<ClaimsLayout />} />
                      <Route path="view-claim/:uuid" element={<ClaimsDetails />} />
                    </Route>
                    <Route path="/members/*" element={<Claims />}>
                      <Route path="" element={<MembersLayout />} />
                      <Route path="hrms/*">
                        <Route path="" element={<MembersHRMS />} />
                        <Route path="products" element={<ProductsHRMS />} />
                      </Route>
                      <Route path="invite-member/*" element={<Bulk intent="INVITE" />}>
                        <Route path="" element={<InviteMemberDetails />} />
                        {/* <Route path=":memberid" element={<InviteMemberDetails />} /> */}
                        <Route path="product-details" element={<InviteMemberProducts />} />
                        {/* <Route path="product-details" element={<InviteMemberProducts />} /> */}
                        <Route path="bulk/*">
                          <Route path="" element={<UploadScreen intent="INVITED" />} />
                          <Route path="review" element={<ReviewScreen intent="INVITED" />} />
                        </Route>
                      </Route>
                      <Route path="employee-requests" element={<EmployeeRequests />} />
                      <Route path="add-member/*" element={<AddMember />}>
                        <Route path="" element={<MemberDetails />} />
                        <Route path=":memberid" element={<MemberDetails />} />
                        <Route path=":memberid/product-details" element={<Product />} />
                        <Route path="product-details" element={<Product />} />
                      </Route>
                      <Route path="add-bulk/*" element={<Bulk intent="Add" />}>
                        <Route path="" element={<UploadScreen intent="ADDITION" />} />
                        <Route path="review" element={<ReviewScreen intent="ADDITION" />} />
                      </Route>
                      <Route path="delete-bulk/*" element={<Bulk intent="Delete" />}>
                        <Route path="" element={<UploadScreen intent="DELETION" />} />
                        <Route path="review" element={<ReviewScreen intent="DELETION" />} />
                      </Route>
                    </Route>
                    <Route path="/settings" element={<SettingsLayout />} />
                  </>
                )}
              </>
            )}
          </>
        )}
        {!isAuthenticated && (
          <>
            <Route path="/" element={<Login />}>
              <Route path="" element={<CheckSSO type="email" />} />
              <Route path="login-with-email" element={<CheckSSO type="email" />} />
              <Route path="login-with-phone" element={<CheckSSO type="phone" />} />
            </Route>
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/set-password" element={<SetPassword />} />
            <Route path="/request-password-reset" element={<ForgotPassword />} />
            {/* <Route path="/request-otp" element={<RequestOTP />} />
            <Route path="/login-with-otp" element={<OTPScreen />} /> */}
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}

        {status === 'INVITED' && (
          <>
            <Route path="/" element={<Navigate to="/invited-member" />} />
            <Route path="/invited-member/*" element={<InvitedMember />} />
            <Route path="/awaiting-approval" element={<AwaitingApproval />} />
          </>
        )}

        {(status === 'PENDING' || status === 'ADDITION_IN_PROCESS') && (
          <>
            {/* <Route path="/" element={<Navigate to="/invited-member" />} />
            <Route path="/invited-member/*" element={<InvitedMember />} /> */}

            <Route path="/" element={<Navigate to="/awaiting-approval" />} />
            <Route path="/awaiting-approval" element={<AwaitingApproval />} />
          </>
        )}
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/set-password" element={<SetPassword />} />

        <Route path="/403" element={returnErrorPage(403)} />
        <Route path="/404" element={returnErrorPage(404)} />
        <Route path="/500" element={returnErrorPage(500)} />
        <Route path="/503" element={returnErrorPage(503)} />
        <Route path="*" element={<Navigate to="/" />} />
        {/* retail */}

        <Route path="/retail-partner/:partner/*">
          <Route path="" element={<RetailLanding />} />
          <Route path="product/:productid" element={<RetailOfferings />} />
          <Route path="offer/:offerid" element={<RetailOfferings />} />
          <Route path="checkout" element={<RetailCheckout />} />
          <Route path="post-payment" element={<RetailPostPayment />} />
        </Route>
        <Route path="ws" element={<WebSocketComponent />} />

        <Route path="/retail/*">
          <Route path="" element={<RetailLanding />} />
          <Route path="product/:productid" element={<RetailOfferings />} />
          <Route path="offer/:offerid" element={<RetailOfferings />} />
          <Route path="checkout" element={<RetailCheckout />} />
          <Route path="post-payment" element={<RetailPostPayment />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default Router;
