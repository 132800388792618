import { Image, Row, Skeleton } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Router from '../../router/Router';
import './_loginlayout.scss';

const LoginLayout: React.FC = () => {
  const navigate = useNavigate(),
    logo = useSelector((state: any) => state.theme.logoUrl);

  return (
    <>
      <div className="login-container">
        <Row justify="center">
        {!logo && <Skeleton.Image className="logo" />}
          {logo && <Image src={logo} preview={false} className="logo" onClick={() => navigate('/')} />}
        </Row>
        <Row justify="center">
          <div className="login-box">
            <div className="login-content">
              <Router />
            </div>
          </div>
        </Row>
        {/* <Row justify="center">
          <div className="footer">© Safetynet Innovations Private Limited</div>
        </Row> */}
      </div>
    </>
  );
};

export default LoginLayout;
