import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './_navbars.scss';

const profileIcon = (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.8163 19.9384C6.42462 18.5052 7.84492 17.5 9.5 17.5H15.5C17.1551 17.5 18.5754 18.5052 19.1837 19.9384M16.5 10C16.5 12.2091 14.7091 14 12.5 14C10.2909 14 8.5 12.2091 8.5 10C8.5 7.79086 10.2909 6 12.5 6C14.7091 6 16.5 7.79086 16.5 10ZM22.5 12.5C22.5 18.0228 18.0228 22.5 12.5 22.5C6.97715 22.5 2.5 18.0228 2.5 12.5C2.5 6.97715 6.97715 2.5 12.5 2.5C18.0228 2.5 22.5 6.97715 22.5 12.5Z"
      stroke="#4B5054"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const offerIcon = (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.98828 9.50781C9.98828 10.0601 9.54057 10.5078 8.98828 10.5078C8.436 10.5078 7.98828 10.0601 7.98828 9.50781C7.98828 8.95553 8.436 8.50781 8.98828 8.50781C9.54057 8.50781 9.98828 8.95553 9.98828 9.50781Z"
      fill="#4B5054"
      stroke="#4B5054"
    />
    <path
      d="M15.9883 15.5078C15.9883 16.0601 15.5406 16.5078 14.9883 16.5078C14.436 16.5078 13.9883 16.0601 13.9883 15.5078C13.9883 14.9555 14.436 14.5078 14.9883 14.5078C15.5406 14.5078 15.9883 14.9555 15.9883 15.5078Z"
      fill="#4B5054"
      stroke="#4B5054"
    />
    <path
      d="M13.2485 3.01855L14.6187 4.3786C14.9548 4.7095 15.4067 4.89604 15.8784 4.89856H17.7986C18.2716 4.89855 18.7254 5.08578 19.0608 5.41931C19.3962 5.75284 19.586 6.20559 19.5886 6.67859V8.60858C19.5937 9.07683 19.7801 9.52487 20.1086 9.85858L21.4685 11.2286C21.6354 11.3932 21.7679 11.5894 21.8584 11.8057C21.9488 12.022 21.9954 12.2541 21.9954 12.4886C21.9954 12.723 21.9488 12.9551 21.8584 13.1714C21.7679 13.3877 21.6354 13.5839 21.4685 13.7486L20.1086 15.1186C19.7804 15.4565 19.5943 15.9075 19.5886 16.3786V18.2986C19.5886 18.7733 19.3999 19.2286 19.0642 19.5643C18.7285 19.9 18.2733 20.0886 17.7986 20.0886H15.8784C15.4067 20.0911 14.9548 20.2777 14.6187 20.6086L13.2485 21.9686C13.0839 22.1355 12.8877 22.268 12.6714 22.3585C12.4551 22.4489 12.223 22.4955 11.9885 22.4955C11.7541 22.4955 11.522 22.4489 11.3057 22.3585C11.0894 22.268 10.8932 22.1355 10.7285 21.9686L9.36865 20.6086C9.03246 20.2777 8.58036 20.0911 8.10864 20.0886H6.17847C5.70546 20.0859 5.25272 19.8962 4.91919 19.5608C4.58566 19.2254 4.39843 18.7716 4.39844 18.2986V16.3786C4.39698 15.9067 4.21022 15.4542 3.87842 15.1186L2.51855 13.7486C2.18635 13.4134 2 12.9605 2 12.4886C2 12.0166 2.18635 11.5638 2.51855 11.2286L3.87842 9.85858C4.21055 9.52729 4.3976 9.07769 4.39844 8.60858V6.67859C4.39844 6.2065 4.58611 5.75374 4.91992 5.41992C5.25374 5.08611 5.70638 4.89856 6.17847 4.89856H8.10864C8.58036 4.89604 9.03246 4.7095 9.36865 4.3786L10.7285 3.01855C11.0637 2.68635 11.5166 2.5 11.9885 2.5C12.4605 2.5 12.9133 2.68635 13.2485 3.01855Z"
      stroke="#4B5054"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path d="M7.98828 16.5078L15.9883 8.50781" stroke="#4B5054" stroke-width="1.5" stroke-miterlimit="10" />
  </svg>
);

const claimIcon = (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.75 23L16.1458 22.2402C15.505 21.9348 14.9636 21.4545 14.5839 20.8547C14.2043 20.2549 14.0019 19.5601 14 18.8502V14H21.5V18.851C21.4981 19.5608 21.2957 20.2557 20.9161 20.8555C20.5364 21.4553 19.995 21.9356 19.3542 22.241L17.75 23ZM15.5 15.5V18.851C15.5013 19.2767 15.6229 19.6934 15.8508 20.053C16.0786 20.4126 16.4034 20.7005 16.7877 20.8835L17.75 21.3395L18.7123 20.8843C19.0967 20.7012 19.4216 20.4131 19.6494 20.0534C19.8773 19.6937 19.9988 19.2768 20 18.851V15.5H15.5Z"
      fill="#4B5054"
    />
    <path
      d="M12.5 21.5003H5V3.50026H11V8.00026C11.0012 8.39772 11.1596 8.77856 11.4407 9.0596C11.7217 9.34065 12.1025 9.49907 12.5 9.50026H17V11.7503H18.5V8.00026C18.5026 7.90169 18.4839 7.80372 18.4451 7.71309C18.4062 7.62246 18.3482 7.54132 18.275 7.47526L13.025 2.22526C12.959 2.15201 12.8778 2.09397 12.7872 2.05512C12.6966 2.01628 12.5986 1.99756 12.5 2.00026H5C4.60254 2.00144 4.2217 2.15986 3.94065 2.44091C3.6596 2.72195 3.50119 3.1028 3.5 3.50026V21.5003C3.50119 21.8977 3.6596 22.2786 3.94065 22.5596C4.2217 22.8407 4.60254 22.9991 5 23.0003H12.5V21.5003ZM12.5 3.80026L16.7 8.00026H12.5V3.80026Z"
      fill="#4B5054"
    />
  </svg>
);

const homeIcon = (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.959 2.1601C12.8259 2.05635 12.662 2 12.4932 2C12.3245 2 12.1606 2.05635 12.0275 2.1601L1.25 10.5639L2.18225 11.7429L3.5 10.7154V19.9996C3.50079 20.3972 3.65908 20.7783 3.94022 21.0594C4.22135 21.3405 4.60242 21.4988 5 21.4996H20C20.3976 21.4988 20.7787 21.3405 21.0598 21.0594C21.3409 20.7783 21.4992 20.3972 21.5 19.9996V10.7221L22.8177 11.7496L23.75 10.5706L12.959 2.1601ZM14 19.9996H11V13.9996H14V19.9996ZM15.5 19.9996V13.9996C15.4996 13.6019 15.3414 13.2206 15.0602 12.9394C14.779 12.6582 14.3977 12.5 14 12.4996H11C10.6023 12.5 10.221 12.6582 9.93978 12.9394C9.65856 13.2206 9.5004 13.6019 9.5 13.9996V19.9996H5V9.5461L12.5 3.7036L20 9.5536V19.9996H15.5Z"
      fill="black"
    />
  </svg>
);

const NavbarFooter: React.FC = () => {
  const userDetails = useSelector((state: any) => state.data.userDetails),
    navbarLinks = [
      {
        id: 1,
        name: 'Home',
        icon: homeIcon,
        navigate: '/home',
        anchor: false,
      },
      {
        id: 2,
        name: 'Claims',
        icon: claimIcon,
        navigate: '/claims',
        anchor: false,
      },
      ...(userDetails?.has_offers
        ? [
            {
              id: 3,
              name: 'Offers',
              icon: offerIcon,
              navigate: 'retail/?utm_source=platform&utm_medium=navbar_btn_mobile&utm_campaign=employee_offer',
              anchor: false,
            },
          ]
        : []),
      {
        id: 4,
        name: 'Profile',
        icon: profileIcon,
        navigate: '/profile',
        anchor: false,
      },
    ];

  return (
    <Row className="navbar-footer" align="middle" justify="space-around">
      {navbarLinks.map((item: any) => {
        return (
          <Col key={item.id}>
            {!item.anchor && (
              <NavLink
                to={item.navigate}
                className={(navData) =>
                  navData.isActive ||
                  (item?.name?.toLowerCase() === 'offers' && window?.location?.pathname?.includes('retail'))
                    ? 'active'
                    : ''
                }
              >
                <div className="navbar-footer-button">
                  <div className="navbar-footer-button-icon">{item.icon}</div>
                  <div className="navbar-footer-button-text">{item.name}</div>
                </div>
              </NavLink>
            )}
            {item.anchor && (
              <div onClick={() => window.open(item.navigate, '_blank')}>
                <div className="navbar-footer-button">
                  <div className="navbar-footer-button-icon">{item.icon}</div>
                  <div className="navbar-footer-button-text">{item.name}</div>
                </div>
              </div>
            )}
          </Col>
        );
      })}
    </Row>
  );
};

export default NavbarFooter;
