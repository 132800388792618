import React from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './_header.scss';

const ToDashboard = ({ className }: { className?: string }) => {
  return (
    <div className={`show-on-mobile to-dashboard ${className}`}>
      <NavLink to="/home" className="link">
        <ArrowLeftOutlined className="back" />
       Back
      </NavLink>
    </div>
  );
};

export default ToDashboard;
